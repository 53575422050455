
import { Course } from "@planetadeleste/vue-mc-learning";
import { Vue, Component, Prop } from "vue-property-decorator";
import StarRating from "vue-star-rating";

@Component({
  components: { StarRating },
})
export default class CourseRating extends Vue {
  @Prop(Object) readonly item!: Course;
  @Prop(Boolean) readonly details!: boolean;

  get css(): Record<string, any> {
    return {
      course__rating: !this.details,
      "course__rating-2": this.details,
    };
  }

  get rating(): number {
    return this.item.get("rating");
  }

  get ratingCount(): number {
    return this.item.get("rating_count");
  }
}
